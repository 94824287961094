
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { FormControl, FormGroup, Col, Row,  Dropdown } from 'react-bootstrap';
import { Spinner } from "reactstrap";
import { toast } from 'react-toastify';

const NewSimRatePlan = ({ isCurrentRatePlanEmpty,currentPlan, SelectedRatePlansMap, setSelectedRatePlans, TestSim ,updatedRatePlans, removeSelectedRatePlan, error,id, ratexl, xl, setRatePlanValue, RatePlans, setSelectedRate, defaultValue, }) => {
    const [SIMQuantity, setSIMQuantity] = useState("")
    const [myOwnValue, setMyOwnValue] = useState("")

    
    const setMyOwnValueTest = (sku, data,id) => {
        setSelectedRatePlans(sku, data, id)
        setMyOwnValue(data.target.textContent)
      }



    const inputHandler = (event,id) => {
        TestSim(event.target.value, id)
       
        if (isCurrentRatePlanEmpty) {
            toast.error("Please select Rate Plan(s).")
            setSIMQuantity("")

            return;
        }
        else {
            setSIMQuantity(event.target.value)  
            setSIMQuantity(event.target.value)
          
        }
    }

const getStyle = () => {
    if (myOwnValue && SIMQuantity.length <= 0) {
        return { border: '1px solid red' };
    }
    return { border: '1px solid rgb(135, 163, 195)' };
};

    return (

        <Row className="mt-3 " style={{ alignItems: "center" }} data-id={id} id={ id}>
            <Col md={12} lg={4} xl={ratexl ? ratexl:2} className="field-root"    >
                <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Rate Plan</span>
            </Col>
            <Col sm={12} md={9} lg={4} xl={xl?xl:4} className=" manage-field-root" >
                <Dropdown  style={{
                    width: '100%', background: "white", display: "flex", justifyContent: "space-between",
                    borderRadius: "0.25rem", border: "solid 0.5px #87a3c3"
                }} tabIndex="2">
                    {updatedRatePlans?.length > 0 ? <span className="p-1 pl-2">{myOwnValue}</span> :
                        <div style={{display:"flex", justifyContent:"center", width:"100%"} }><Spinner /></div>    
                        }
                    <Dropdown.Toggle tabIndex="3" id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" style={{ width: "100%" }}>
                        {updatedRatePlans?.map(data => {
                            return (
                                <>
                                    <Dropdown.Item data-id={id} eventKey={data.skuNumber} onClick={(data) => {
                                        setMyOwnValueTest(data.target.dataset.sku, data, data.target.dataset.id)
                                    }} data-sku={data.skuNumber} key={data.skuNumber} tabIndex="4">{data.description}

                                    </Dropdown.Item>

                                </>
                            )
                        })}


                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col sm={12} md={12} lg={1} xl={1} className="field-root"    >
                <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Quantity</span>
            </Col>
            <Col sm={3} md={9} lg={2} xl={1} className=" manage-field-root" >
                <FormGroup controlId="Quantity" id={id}>
                    <FormControl
                        style={getStyle()}
                        value={SIMQuantity}
                        onChange={(data) => { inputHandler(data, id) }}
                        autoComplete="Quantity"
                        className="input-manage-field"
                        type="text" tabIndex="5" />
                </FormGroup>
            </Col>
        </Row>
        )

}

export default NewSimRatePlan;