import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import OrderSimandRatePlan from "./OrderSimandRatePlan"
import Modal from 'react-bootstrap/Modal';
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom"
import NewSimRatePlan from "./NewSimRatePlan"
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";


class OrderNewSim extends Component {
    constructor(props) {
        super(props);
       //ocument.title = "Usage - Summary"
    }
    
    state = {

        orderType: "OrderSimandRatePlans",
        RatePlans: [],
        unFilterRatePlans: [],
        updatedRatePlans:[],
        SelectedRatePlans:[],
        MAXRatePlan: 4,
        RatePlanCounter: 0,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        businessPhone: "",
        email: "",
        selectedRate:"",
        SIMQuantity: "",
        processingOrder: false,
        internalCustomerId: "",
        internalOrderId: "",

        currentRatePlanCounter: 0,
        simDuplicationError: false,
        isCurrentEmpty:false,

        porcessModal: false,
        currentPlan :null,

        SelectedRatePlansMap: null,

        //change this later
        NewUpdateRatePlans: [],

        selectedRatePlanResult: [],

        //add to current array of RatePlans
        currentSelectedRatePlans:[],

        /* address*/
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        isModalOpen: false,
        error: false,
        createOrderAttempt: false,
        submitRatePlan: [],
        orderLimit: false,
        createOrderError:null



    }
    componentDidMount = () => {
     
        this.fecthRatePlans()
        
}

    setCurrentRatePlansCounter = (id) => {
        this.setState({ currentRatePlanCounter: id }, () => { })

    }

    removeRatePlanFromMap = (id) => {

        const exist = this.doesRatePlanExist(id)
        if (exist) {
            this.setState({ currentRatePlanCounter: this.state.currentRatePlanCounter - 1 }, () => {
                this.state.SelectedRatePlansMap.delete(id.toString());
                return;
            })
          }
      }

    setRatePlanMap = (sku, data, id) => {

        let MapRatePlan = new Map(this.state.SelectedRatePlansMap)
        localStorage.setItem('idsetrateplan',id)
        let currentMapData = {}
        this.setState({ currentRatePlanCounter: id, isCurrentEmpty: false }, () => {
            const found = this.state.RatePlans.filter(rate => rate.skuNumber === sku)
            const selectedData = {
                "description": found[0].description,
                "SIMQuantity": "",
                "unitCost": found[0].price,
                "SKU": found[0].skuNumber,
                "SIMNumber": null,
                "replacedSIMNumber": null,
                "ItemStatus": null,
                "activationCost": null,
                "discountMRC": null,
                "discountMRCSurcharge": null,
                "discountactivationCost": null,
                "discountactivationSurcharge": null,
                "productCode": null,}

            if (this.state.SelectedRatePlansMap) {

                //loop through the
                for (let [key, value] of this.state.SelectedRatePlansMap) {
                    if (sku === value['SKU'] && id !== key) {
                        toast.error("Unable to select the same Rate Plan twice. Please ensure each Rate Plan is selected only once.")
                        this.setState({ simDuplicationError: true })
                        return;

                    }
                    else {
                        this.setState({ simDuplicationError: false })
                        

                    }
                   
                }

                currentMapData = this.state.SelectedRatePlansMap
                const isExist = this.doesRatePlanExist(id)
                if (isExist?.['SKU'] === sku) {
                    //toast.error("cannot selected same sims again")
                   // return;

                }
                else if (id === this.state.currentRatePlanCounter) {
                    const data = this.state.SelectedRatePlansMap;
                    data.delete(id)
                    MapRatePlan.set(id, selectedData)
                    this.setState({ SelectedRatePlansMap: MapRatePlan, }, () => {
                    })
                }
            }
            else {
                MapRatePlan.set(id, selectedData)
                this.setState({ SelectedRatePlansMap: MapRatePlan, }, () => {
                })
            }


        })


    }
    doesRatePlanExist = (id) => {
        if (!this.state.SelectedRatePlansMap) return;

        const data = this.state.SelectedRatePlansMap;
        const found = data.get(id.toString())
        return found;

    }

    createNewOrder = () => {
        const { usageData } = this.props
        this.setState({ createOrderAttempt:true})
        if (this.state.simDuplicationError) {
            toast.error("Unable to submit an order with duplicate Rate Plans. Please ensure each Rate Plan is included only once.")
            return;

        }
        if (this.state.SelectedRatePlansMap === null || this.state.SelectedRatePlansMap.get(((this.state.currentRatePlanCounter > 0 ? this.state.currentRatePlanCounter:1)-1).toString()) === undefined) {
            localStorage.setItem('rateplan', JSON.stringify(this.state.SelectedRatePlansMap))
            toast.error("Please select a Rate Plan before submitting an order.")
            return;
        }
        
        const SubmitRatePlans = Array.from(this.state.SelectedRatePlansMap.values())
        let lastCounter = SubmitRatePlans.length - 1;
      
        for (let [key, value] of this.state.SelectedRatePlansMap) {
           if (value['SIMQuantity'].length <= 0) {
               toast.error("Please enter a quantity for each Rate Plan.")
               return;

            }
            if (value['SKU'] === SubmitRatePlans[lastCounter]['SKU'] && key != lastCounter.toString() ) {
                toast.error("Unable to submit an order with duplicate Rate Plans. Please ensure each Rate Plan is selected only once.")
                return;
            }
            else {
                lastCounter-=1
            }

        }
        
        for (let index = 0; index < SubmitRatePlans.length; index++) {
            if (SubmitRatePlans[index]['SIMQuantity'] >= 100) {
                this.setState({ orderLimit:true})
            }
        }
        if (this.state.businessPhone.length > 0 && this.state.internalOrderId.length > 0 && this.state.email.length > 0 &&
            this.state.firstName.length>0 && (this.state.lastName.length > 0) 
            ) {

            this.setState({ error: false })
            //shipping information is the billing information
            const CreateOrderPayload = {
                "threadId": uuidv4(),
                "createdDateTime": new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
                "orderType": "Activation",
                "provider": "Advantix",
                "payload": {
                    "orderId": null,
                    "orderNumber": null,
                    "internalOrderId": this.state.internalOrderId,
                    "accountSetupFee": null,
                    "replacementFee": null,
                    "overageRatePlanCost": null,
                    "suspendPlanCost": null,
                    "discountingPriceApproval": false,
                    "billingToken": null,
                    "termsAndConditionsVerId": null,
                    "primaryCarrier": null,
                    "shippingAddress1": usageData['Address1'],
                    "shippingAddress2": "",
                    "shippingCity": usageData['City'],
                    "shippingState": usageData['State'],
                    "shippingPostalCode": usageData['Zip'],
                    "simFulfilmentType": "CurrentSIM",
                    "account": {
                        "sfAccountId": localStorage.getItem('salesforceId'),
                        "internalCustomerId": localStorage.getItem('InternalCustomerId'),//"0007023893",//?
                        "masterAccountId": localStorage.getItem('masterAccountId'),
                        "partnerAccountId": "",
                        "productName": "MMS-RWSC",
                        "companyName": usageData['CustomerName'],
                        "billingAddress1": usageData['Address1'],
                        "billingAddress2": "",
                        "billingCity": usageData['City'],
                        "billingState": usageData['State'],
                        "billingPostalCode": usageData['Zip'],
                        "contactFirstName": this.state.firstName,
                        "contactLastName": this.state.lastName,
                        "contactEmail": usageData['BillingContactEmail'],
                        "businessPhone": this.state.businessPhone,
                        "mobilePhone": this.state.phoneNumber,
                        "providerPaymentMethod": null,
                        "netTermsDays": null,
                        "mvnOPortalAccess": false,
                        "inventorySource": null,
                        "expirationDate": null

                    },
                    "charges": null,
                    "ratePlanOrderItems": SubmitRatePlans,
                    "paymentportal": null,

                    "PortalUser": {
                        "PortalUserName": localStorage.getItem('PortalUsername'),
                        "FirstName": this.state.firstName,
                        "LastName": this.state.lastName,
                        "Email": localStorage.getItem('PortalUserEmail'),
                        "PortalAccountName": localStorage.getItem('PortalUserAccountName'),
                        "PortalAccountId": localStorage.getItem('PortalUserAccountId')

                    },

                    "opportunity": null,
                    "requestSource": "tlm",
                    "groupNameValue": []

                }
            }



            this.setState({ processingOrder: true, porcessModal:true })

            fetch("api/OrderController/CreateOrder", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                method: "post",
                body: JSON.stringify({
                    ...CreateOrderPayload

                })
            }).then(response => {
                return response.json().then(data => {
                    if (data) {
                        const { payload}= data
                        this.setState({
                            processingOrder: false,
                             internalCustomerId: payload['internalCustomerId'] ,
                            internalOrderId: payload['internalOrderId'],
                        })
                    }
                })

            }).catch(err => {
                this.setState({ createOrderError:err})

            })
        }
        else {
            this.setState({  error:true })
        }
    }

    setSelectedRate = (data) => {
        //add the plan here
        let temp = []
        temp.push(data)
        this.setState({ submitRatePlan: [...this.state.submitRatePlan,...temp] }, () => {
        })
        this.setState({ selectedRate: data })

    }

    setContontactInformation = (data) => {
        this.setState({
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            businessPhone: data.businessPhone,
            email: data.email,

        }, () => {
            
        })
       
    }


    removeSelectedRatePlan = (id) => {
        let temp = this.state.RatePlans
        for (let index = 0; index < this.state.RatePlans.length; index++) {
            if (this.state.RatePlans[index]['description'] === id) {
                temp.splice(index, 1)
                this.setState({ updatedRatePlans:[...temp]})

            }

        }


    }

    setSimQuality = (data,id) => {
        const currentPlan = this.state.SelectedRatePlansMap?.get(id)
        if (this.state.simDuplicationError) {
            toast.error("Unable to submit an order with duplicate Rate Plans. Please ensure each Rate Plan is selected only once.")
            return;

        }
        else if (currentPlan === undefined) {
            toast.error("Please select a Rate Plan.")
            return;
        }
       else if (!this.state.SelectedRatePlansMap ||  currentPlan['SKU'] === undefined) {
           
            this.setState({ isCurrentEmpty: false }, () => {
                toast.error("Please select a Rate Plan.")

            })
            return;
       }
        this.setState({ currentPlan: currentPlan })

         if ( this.state.simDuplicationError) {
             toast.error("Unable to submit an order with duplicate Rate Plans. Please ensure each Rate Plan is selected only once.")
            return;
         }   
        const updateObject = {
            ...currentPlan,
            "SIMQuantity": data,
        }
        let MapRatePlan = new Map(this.state.SelectedRatePlansMap)
        MapRatePlan.set(id,updateObject)

        this.setState({ SelectedRatePlansMap: MapRatePlan, isCurrentEmpty: false }, () => {

        })

    }

    setShippInformation = (data) => {
        this.setState({
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
        })

    }

    //get RatePlans
    fecthRatePlans = () => {
        fetch("api/OrderController/GetRatePlans", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "providerName": "Advantix",
                "salesForceId": "",
                "sfAccountId": localStorage.getItem('salesforceId'), // you will get it from the current context
                "isReseller": "false",
                "isOrder": "true",
                "resellerAccountId": ""

            })
        }).then(response => {
            return response.json().then(data => {
                this.setState({  updatedRatePlans:data,currentSelectedRatePlans: data.slice(0, 1),unFilterRatePlans: data, RatePlans: data, SelectedRatePlans: data.slice(0, 1), selectedRate:data[0].description  }, () => {

                })
                let temp = []

                temp.push(<NewSimRatePlan
                    setSelectedRate={this.setSelectedRate}
                    RatePlans={this.state.RatePlans}
                    updatedRatePlans={this.state.updatedRatePlans}
                    currentSelectedRatePlans={this.state.currentSelectedRatePlans}
                    defaultValue={"Test"}
                    setQuanity={(data) => this.setState({ SIMQuantity: data })}/>)
                this.setState({ NewUpdateRatePlans: temp }, () => {
                })

            })

        })
    }
   
    addRatePlan = () => {
        let newRatePlan = [];
        let updateRatePlan=[]
        if (this.state.RatePlanCounter < this.state.MAXRatePlan-1) {
            this.setState({ RatePlanCounter: this.state.RatePlanCounter + 1 ,
                }, () => {
                updateRatePlan.push(this.state.unFilterRatePlans[this.state.RatePlanCounter])

                this.setState({
                    currentSelectedRatePlans: [...this.state.currentSelectedRatePlans, ...updateRatePlan],
                    SelectedRatePlans: [...this.state.NewUpdateRatePlans, ...newRatePlan],
                }, () => {
                    newRatePlan.push(<NewSimRatePlan
                        RatePlans={this.state.RatePlans}
                        setSelectedRate={this.setSelectedRate}
                        currentSelectedRatePlans={this.state.currentSelectedRatePlans}
                        defaultValue={"Test"}
                        setQuanity={(data) => this.setState({ SIMQuantity: data })}
                    />)
                    this.setState({
                        NewUpdateRatePlans: [...this.state.NewUpdateRatePlans, ...newRatePlan]
})
                }, () => {

                })     
            })
        }
    }

    removeRatePlan = () => {

        let tempArray = this.state.SelectedRatePlans
        if (this.state.RatePlanCounter > 0) {

            this.setState({ RatePlanCounter: this.state.RatePlanCounter - 1 }, () => {

                tempArray.pop()
                this.setState({ SelectedRatePlans: [...tempArray] }, () => {
                })
            })
        }
    }

 
    render() {
        return (
            <>
                <Row className="mb-4">
                    <Col lg={12} style={{padding:0} }>
                        <h2 className="mb-5 fw-bold">Order Rate Plan(s)</h2>
                        {(this.state.businessPhone.length == 0 || this.state.internalOrderId.length == 0 || this.state.email.length == 0 ||
                            this.state.firstName.length == 0 || (this.state.lastName.length == 0)) && this.state.createOrderAttempt ? <Col>
                            <div style={{ background: "rgb(200 62 62 / 19%)", height: "auto", padding: "1rem" }}>
                                {this.state.firstName.length > 0 ? null:
                                    <h2 style={{ color: "#b01414", fontSize: "1rem", padding: "0.4rem" }}> <span><span style={{ fontWeight: "bold", marginRight: "0.4rem" }}>First Name</span> is required</span></h2>}
                                <h2 style={{ color: "#b01414", fontSize: "1rem", padding: "0.4rem" }}>{this.state.lastName.length > 0 ? null : <span><span style={{ fontWeight: "bold", marginRight: "0.4rem" }}>Last Name</span> is required</span>}</h2>
                                <h2 style={{ color: "#b01414", fontSize: "1rem", padding: "0.4rem" }}>{this.state.email.length > 0 ? null : <span><span style={{ fontWeight: "bold", marginRight: "0.4rem" }}>Email</span> is required</span>}</h2>
                                <h2 style={{ color: "#b01414", fontSize: "1rem", padding:"0.4rem" }}>{this.state.businessPhone.length > 0 ? null : <span><span style={{fontWeight:"bold", marginRight:"0.4rem"} }>Business Phone Number</span> is required</span>}</h2>
                                <h2 style={{ color: "#b01414", fontSize: "1rem", padding: "0.4rem" }}>{this.state.internalOrderId.length > 0 ? null : <span><span style={{ fontWeight: "bold", marginRight: "0.4rem" }}>Order Id</span> is required</span>}</h2>

                            </div>
                        </Col>:null}
                        
                    </Col>
                </Row>
                <OrderSimandRatePlan removeRatePlanFromMap={this.removeRatePlanFromMap} isCurrentEmpty={this.state.isCurrentEmpty} simDuplicationError={this.state.simDuplicationError} currentPlan={this.state.currentPlan} setCurrentRatePlansCounter={this.setCurrentRatePlansCounter} usageData={this.props.usageData} setSimQuality={this.setSimQuality} SelectedRatePlansMap={this.state.SelectedRatePlansMap} setRatePlanMap={this.setRatePlanMap} updatedRatePlans={this.state.updatedRatePlans} removeSelectedRatePlan={this.removeSelectedRatePlan} error={this.state.error} SIMQuantity={this.state.SIMQuantity} setQuanity={(data) => this.setState({ SIMQuantity: data })} updateOrderId={(data) => {
                    this.setState({ internalOrderId: data }) 
                }} OrderId={this.state.internalOrderId}  MAXRatePlan={this.state.MAXRatePlan} RatePlanCounter={this.state.RatePlanCounter} setSelectedRate={this.setSelectedRate} selectedRate={this.state.selectedRate} SelectedRatePlans={this.state.NewUpdateRatePlans} ratePlanCounter={this.state.RatePlanCounter} setShippInformation={this.setShippInformation} setContontactInformation={this.setContontactInformation} RatePlans={this.state.RatePlans} addPlan={this.addRatePlan}
                    removePlan={this.removeRatePlan} />

                <Button onClick={this.createNewOrder} tabIndex="14" className="mt-4"><span>Place Order</span></Button>

                <Modal size="lg" centered style={{ padding: "2rem" }} show={this.state.porcessModal} >
                    {this.state.createOrderError ?
                        <>
                        <Modal.Title style={{ padding: "1rem" }}>Error has occurred when processing your Order</Modal.Title>
                                          <Modal.Body >
                                <h2>this.state.createOrderError</h2>
                                     </Modal.Body></>:
                         <>
                             <Modal.Title style={{ padding: "1rem" }}>{this.state.processingOrder ?null:"Requested Submitted"}</Modal.Title>
                    <Modal.Body >
                        <Row className="mt-3 " style={{  display: "flex", justifyContent: "start", textAlign: "left", width:"100%" }} >

                            
                            <Col style={{ marginBottom: "2rem", fontSize: "1rem", justifyContent:"center", display:"flex" }} xl={12}>{this.state.processingOrder ? <div>
                                <span style={{ marginRight: "1rem", display: "block", marginBottom: "1rem" }}> {this.state.orderLimit ? "Thank you for placing your order.  Due to the order quantity, this order may take a few minutes to finish processing.  After processing has completed, you will receive an order confirmation email." :"Creating Your Order....."}</span>
                                <Spinner style={{margin:"auto", display:"flex", fontSize:"1.1rem"}} />
                            </div>:
                                "Your Order has been recieved. Please return to the portal when you are ready to provision or activate this order. If you need to contact Advantix about your order, please provide the following information:"}</Col>
                            <Col xl={12}>
                                {this.state.processingOrder ? null :  `Customer ID: ${this.state.internalCustomerId}`}
                            </Col>
                            <Col xl={12}>
                                {this.state.processingOrder ? null : `Order ID: ${this.state.internalOrderId}` }
                           
                            </Col>
                            {this.state.processingOrder ? null : <Col xl={12} style={{ marginTop: "1rem", marginBottom: "1rem" }}>

                                        <Link style={{ background: "#2c7be5", padding: "0.5rem", color: "white" }} to= "/pendingrequest">Return to Home</Link>

                            </Col> }
                            
                        </Row>



                            </Modal.Body>
                        </>
                    
                    }
               
                </Modal>
            </>
        
        
            )
    }

}


export default OrderNewSim