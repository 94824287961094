import React, { Component } from 'react';
import InventoryTable from "./InventoryTable";
import { Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";

export class Inventory extends Component {
    constructor(props) {
        document.title = "Advantix SmartSIM Portal - Inventory"
        super(props);
        this.state = {
            Formstatus: '',
            count: '',
            inventoryjson: null,
        }
    }

    componentDidMount() {
        fetch('/api/SmartSIMInventoryController/GetInventoryData', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post'
        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (Array.isArray(data)) {
                    if (data.length > 0) {
                        this.setState({
                            Formstatus: "Complete",
                            count: data.length,
                            inventoryjson: data,
                        })
                    }
                } else {
                    throw new Error("Error retrieving inventory details.");
                }
            });
        }).catch((ex) => {
            console.log(`error: ${ex}`);
            this.setState({ inventoryjson: [] });
            toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
        });
    }

    render() {
        return (
            <>
               
                    
                <InventoryTable data={this.state.inventoryjson} setIsSelected={false} usageData={this.props.usageData }/>
                    
               
            </>
        );
    }
}