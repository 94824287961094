
import { FormControl, FormGroup, Col, Row, Dropdown } from 'react-bootstrap';
import { themeColors } from "../../helpers/utils";
import { useState, useEffect} from "react"
import { Spinner } from "reactstrap";

const ContactInformation = ({ usageData, error, setContactInformations }) => {
    const [isXlScreen, setIsXlScreen] = useState(window.innerWidth >= 1200);
    const[contactList, setContactLits] = useState([])
    const [saveContact, setSaveDropContact] = useState("")
    const [contactObject, setContactObject] = useState({})
    const [firstName, setFirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [businessPhone, setbusinessPhone] = useState("")
    const [mobilePhone, setMobilePhone] = useState("")
    const [email, setEmail] = useState("")
    const regexCheck = /^\d{10}$/;
    const formatPhoneNumber = (input) => {
        return input.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    };

    useEffect(() => {
         getAllContacts()

    }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsXlScreen(window.innerWidth >= 1200);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const customColStyle = isXlScreen
        ? { flex: '0 0 16.66667%', maxWidth: '17.66667%' }
        : {};


    const getAllContacts = () => {

        fetch("api/OrderController/GetAllContacts", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId')

            })
        }).then(response => {
            return response.json().then(data => {
                console.log(data)
                setContactLits(data)
            })

        })

    }

    const selectedontact = (data) => {
        if (!data) {
            const clearData = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                businessPhone: ''
            }
            setContactInformations(clearData)
            setbusinessPhone('')
            setMobilePhone('')
            setlastName('')
            setFirstName('')
            setEmail('')
            return;
        }
        const id = data.target.dataset.id
        const findContact = contactList.find(data => {
            return data['ContactId'] === id})
        const { ContactName, ContactEmail, ContactPhone, ContactMobilePhone } = findContact
        const firstName = ContactName.split(" ")[0]
        const lastName = ContactName.split(" ")[1]
        setbusinessPhone( ContactPhone)
        setMobilePhone( ContactMobilePhone)
        setlastName(lastName)
        setFirstName(firstName)
        setEmail(ContactEmail)
     
        const Updatedata = {
            firstName: firstName,
            lastName: lastName,
            email: ContactEmail,
            phoneNumber: mobilePhone,
            businessPhone: ContactPhone
        }
        setContactInformations(Updatedata)
      }

    const handleInputChange = (input, setPhoneState) => {
        // Allow only digits and hyphen
        const sanitizedInput = input.replace(/[^0-9-]/g, '');
        setPhoneState(sanitizedInput);
    };

    const formatBusinessPhoneNumber = (input) => {
        if (regexCheck.test(input)) {
            const formatedBusinessNumber = formatPhoneNumber(input);
            setbusinessPhone(formatedBusinessNumber);
        } else {
            setbusinessPhone(input);
        }
    };

    const formatMobilePhoneNumber = (input) => {
        if (regexCheck.test(input)) {
            const formatedMobNumber = formatPhoneNumber(input);
            setMobilePhone(formatedMobNumber);
        } else {
            setMobilePhone(input);
        }
    };

    const setContactInformation = () => {

        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: mobilePhone,
            businessPhone:businessPhone
        }
        setContactInformations(data)
    }

    return (
        <Row>
            <h6 className='mb-3 fw-bold'>Contact Information for Order</h6>
            <Row>
                <Col className="" md={4} lg={4} xl={2} >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Saved Contact</span>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="mb-3" >
                    <Dropdown tabIndex="6" style={{
                        width: '100%', background: "white", display: "flex", justifyContent: "space-between",
                        borderRadius: "0.25rem", border:"solid 0.5px #87a3c3 "
                    }} >
                        <span className="p-1 pl-2">{saveContact}</span>
                        
                        <Dropdown.Toggle tabIndex="7" id="dropdown-basic">
                         
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end" style={{ width: "100%", height: "20rem", overflow: "auto" }}>
                            <Dropdown.Item key="empty" data-id="" onClick={() => {
                                setSaveDropContact('');
                                selectedontact(null);
                            }} tabIndex="8" >Clear Selection</Dropdown.Item>

                            {contactList.length > 0 ?
                                contactList.map((contact, index) => {
                                    return (
                                        <Dropdown.Item key={contact['contactId']} data-id={contact['ContactId']} onClick={(data) => {
                                            const target = data.target;
                                            if (target && target.dataset) {
                                                selectedontact(data);
                                                setSaveDropContact(data.target.textContent);
                                            } 
                                        }} tabIndex="8" >{contact['ContactName']}</Dropdown.Item>
                                    )
                                })
                                : <div style={{ display: "flex", justifyContent: "center", width: "100%" }}><Spinner /></div>
                            }
                        </Dropdown.Menu>                       
                    </Dropdown>
                </Col>
            </Row>

            <Row >
                <Col className="" md={4} lg={4} xl={2} >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Contact First Name</span>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="mb-3" >
                    <FormGroup controlId="ContactFirstName" >
                        <FormControl style={{ border: error && firstName.length<=0? '1px solid red' : '1px solid rgb(135, 163, 195)' } }
                            defaultValue={firstName}
                            onChange={(event) => {
                            setFirstName(event.target.value) 
                            setContactInformation()
                            }} tabIndex="9" autoComplete="Contact First Name" className=" input-manage-field" type="text" />
                    </FormGroup>
                </Col>
                <Col className="" md={4} lg={4} xl={2} style={customColStyle} >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title ">Business Phone Number</span>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                    <FormGroup controlId="BusinessPhoneNumber" >
                        <FormControl style={{ border: error&& businessPhone.length<=0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                            value={businessPhone}
                            onChange={(event) => {
                                setbusinessPhone(event.target.value)
                                setContactInformation()
                                handleInputChange(event.target.value, setbusinessPhone)
                            }}
                            onBlur={(e) => formatBusinessPhoneNumber(e.target.value)}
                            autoComplete="Business Phone Number" tabIndex="12" className=" input-manage-field" type="text" />
                    </FormGroup>
                </Col>
            </Row>
        

            <Row>
                <Col className="" md={4} lg={4} xl={2} >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Contact Last Name</span>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="mb-3" >
                    <FormGroup controlId="ContactLastName" >
                        <FormControl style={{ border: error && lastName.length<=0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                            defaultValue={lastName}
                            onChange={(event) => {
                                setlastName(event.target.value)
                                setContactInformation()
                            }}
                            autoComplete="Contact Last Name" tabIndex="10" className=" input-manage-field" type="text" />
                    </FormGroup>
                </Col>
                <Col className="" md={4} lg={4} xl={2} style={customColStyle} >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title ">Mobile Phone Number</span>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                    <FormGroup controlId="MobilePhoneNumber" >
                        <FormControl style={{ border: error && mobilePhone.length<=0? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                            value={mobilePhone}
                            onChange={(event) => {
                                setMobilePhone(event.target.value)
                                setContactInformation()
                                handleInputChange(event.target.value, setMobilePhone)
                            }}
                            onBlur={(e) => formatMobilePhoneNumber(e.target.value)}
                            autoComplete="Mobile Phone Number" tabIndex="13" className=" input-manage-field" type="text" />
                    </FormGroup>
                </Col>
            </Row>
            <Row  >
                <Col className="" md={4} lg={4} xl={2}  >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Email Address</span>
                </Col>
                <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                    <FormGroup controlId="EmailAddress" >
                        <FormControl style={{ border: error && email.length<=0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                            defaultValue={email}

                            onChange={(event) => {
                                setEmail(event.target.value)
                                setContactInformation()
                            }}
                        
                            autoComplete="Email Address" tabIndex="11" className=" input-manage-field" type="email" />
                    </FormGroup>
                </Col>
            </Row>

        </Row>

    )

}




export default ContactInformation;