import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
import { Spinner } from "reactstrap";
import PresentationComponent from "./SharedComponent/PresentationComponent";
import ChangeService from '../common/ContextMenu.js';

class InventoryData extends Component {

    constructor(props) {
        super(props);
        document.title = "Advantix SmartSIM Portal -Inventory - Detail- Inventory"
    }
    state = {
        isLoading: false,
        inventoryData: null,
        BasicInformationData: null,
        AssetInformationData: null,
        ReportDetailsData: null,
    }

    componentDidMount() {
        this.fetchInventoryData()
    }




    fetchInventoryData = () => {
        const simNumber = this.props.location.pathname.split("/")[2]
        this.setState({ isLoading: true })

        fetch('api/SmartSIMInventoryController/GetInventoryDetails', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({ "SIMNumber": simNumber })

        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data) {
                    const { Provider, GroupValues, AssetProperties,
                        Username, RatePlanName, RatePlanCost, SIMLocationCity, SIMLocationState,
                        SecondaryId, ServiceType } = data;

                    const basicTransformData = {
                        "User Description": Username,
                        "Secondary ID": SecondaryId,
                        "Provider": Provider,
                        "Location": SIMLocationState ? SIMLocationState : '' + ', ' + SIMLocationCity ? SIMLocationCity : '',
                        'Service Type': ServiceType,
                        "Rate Plan": RatePlanName,
                        "Rate Plan Cost": '$' + RatePlanCost.substring(0, 5),
                    }
                    this.setState({
                        BasicInformationData: basicTransformData, AssetInformationData: AssetProperties, ReportDetailsData: GroupValues,
                        isLoading: false
                    })
                }
                else {
                    this.setState({ isLoading: false })
                }

            });
        }).catch((ex) => {
            this.setState({ isLoading: false });
            toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
        });
    }

    handleNavigation = (link) => {
        const simNumber = this.props.location.pathname.split("/")[2]
        const path = `${link}/${simNumber}`
        this.props.history.push(path)
    }


    render() {
        const { isLoading } = this.state
        const simNumber = this.props.location.pathname.split("/")[2]

        return (
            <>
                {isLoading ?
                    <div className="d-flex mt-11">
                        <Spinner className="m-auto" style={{ color: themeColors.primary }} type="grow" />
                    </div> : <>
                        <div>
                            <h4 className='text-secondary mb-2'>Inventory - Detail- Inventory</h4>
                            <Row className='mt-3'>
                                <Col md={4} sm={6} lg={3} >
                                    <h4 className='mt-3 inventory-headline'>Asset Details:</h4>
                                    <h5 className='text-secondary ml-3 mb-4 mt-4' style={{ whiteSpace: 'nowrap' }}>SIM #: {simNumber} </h5>
                                </Col>
                                <Col md={12} sm={12} lg={5} >
                                    <Button as="Link" onClick={() => this.handleNavigation('/inventorydetails-live-data')} className="not-active-button mr-2"><span>Live Data</span></Button>
                                    <Button as="Link" onClick={() => this.handleNavigation('/inventorydetails-inventory')} className='active-button ' ><span>Inventory</span></Button>                          
                                    <div style={{ marginTop: '-10.5%', marginLeft: '85%' }}>
                                        <ChangeService ></ChangeService></div>
                                </Col>
                                <Col md={1} className="mb-sm-3" >
                                    <Button as="Link" onClick={() => this.handleNavigation('/inventorydetails-edit-inventory')} className=' not-active-button' style={{ width: "8rem" }} >
                                        <span>Edit Asset Profile</span></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    <Col className="mb-4 mt-4" md={6}>
                                        {this.state.BasicInformationData ?
                                            <PresentationComponent
                                                data={this.state.BasicInformationData}
                                                Title="Basic Information"

                                                FirstColsm={5} FirstColmd={8} FirstCollg={6}
                                                SecondCollg={3} SecondColmd={8} SecondColsm={7} /> :
                                            <h5 className="text-center" style={{ color: themeColors.warning }}>No Data</h5>}
                                    </Col>
                                </Col>

                                <Col md={5}>
                                    <Col className="mb-4 mt-4" md={6} >
                                        {this.state.AssetInformationData ?
                                            <PresentationComponent
                                                data={this.state.AssetInformationData}
                                                Title="Asset Properties"
                                                FirstColsm={5} FirstColmd={8} FirstCollg={9}
                                                SecondCollg={2} SecondColmd={8} SecondColsm={5}
                                            />
                                            : <h5 className="text-center" style={{ color: themeColors.warning }}>No Data</h5>}
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} >
                                    <Col className="mb-4 mt-4" md={6}>
                                        {this.state.ReportDetailsData ?
                                            <PresentationComponent
                                                data={this.state.ReportDetailsData}
                                                Title="Report Details"
                                                FirstCollg={7} FirstColsm={12} FirstColmd={8}
                                                SecondCollg={3} SecondColmd={5} SecondColsm={12} />
                                            : <h5 className="text-center" style={{ color: themeColors.warning }}>No Data</h5>}
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            </>
        );
    }
}
export default withRouter(InventoryData);