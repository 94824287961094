import Modal from 'react-bootstrap/Modal';
import { Row, Col, Button, FormControl, FormGroup } from 'react-bootstrap';
import React, { useState } from "react";
import { themeColors } from '../../helpers/utils';
import "./ManageReport.css";
import GroupCellRender from './GroupCellRender';
import { AgGridReact } from 'ag-grid-react';
import { useEffect } from 'react';
import { Card, CardBody, Spinner } from "reactstrap";

//TODO: change file name and update all the components
//TODO: move the modal to separate component
const DetailsCellRender = (data) => {
    const [deatils, setDetails] = useState(false)
    const [groupValue, setGroupValue] = useState("")
    const [newObjectFormat, setNewObjectFormat] = useState()

    const addGroupValue = () => {
        data.setSelctedGroupName(groupValue, data.data.GroupName)
        data.addGroupValue()
        if (data.isValid) {
            setDetails(false)
        }

    }

    const onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };
    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    };
    const columnDefs = [
        {
            headerName: 'Group Value',
            headerTooltip: "Group Value",
            field: 'GroupValue',
            tooltipField: "Group Value",
            minWidth: 200,

        },



    ];
    useEffect(() => {
        TransformData()
    }, [data.value])

    const TransformData = () => {
        const currentData = data.value
        const newArrayFormat = []
        let newObject = {}

        for (let index = 0; index <= currentData.length - 1; index++) {
            newObject = {
                "GroupValue": currentData[index]
            }
            newArrayFormat.push(newObject)


        }
        setNewObjectFormat(newArrayFormat)

    }

    return (
        <>

            <Modal size="lg" centered show={deatils} onHide={() => { }}>
                <Modal.Header closeButton onClick={() => { setDetails(false) }}>
                    <Modal.Title style={{ fontSize: "1.3rem" }}>Reporting Group Values</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{}}>
                    <Row className="d-flex" style={{ backgroundColor: "rgb(88 138 205 / 62%)", justifyContent: "center" }}>
                        <Col className="d-flex" style={{ justifyContent: "center" }}>
                            <h4 className="" style={{ fontWeight: "bold", color: "rgb(29 114 229)", position: "relative", top: "0.4rem" }}>{data.data.GroupName}</h4></Col>
                    </Row>

                    <Row className="mt-3 " style={{ alignItems: "center" }} >
                        <Col sm={12} md={3} lg={2} xl={2} className="field-root"    >
                            <h2 style={{ fontSize: "1rem" }} className="live-data-title">Group Value:</h2>
                        </Col>
                        <Col sm={12} md={7} lg={7} xl={7} className=" manage-field-root" >
                            <FormGroup controlId="GroupValue" >
                                <FormControl style={{ border: groupValue.length <= 0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }} onChange={(event) => {
                                    setGroupValue(event.target.value)
                                }} autoComplete="GroupValue" className=" input-manage-field" autoFocus type="text" />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={2} lg={2} xl={2} className="group-button">
                            <Button onClick={() => {
                                addGroupValue()

                            }} className="p-2  " style={{ width: "100%" }}><span>Add</span></Button>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="ag-theme-alpine grid">
                                        <AgGridReact
                                            rowHeight={35}
                                            onGridReady={onGridReady}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            rowData={newObjectFormat}
                                            frameworkComponents={{
                                                customLoadingOverlay: () => <Spinner type="grow" />,
                                                customNoRowsOverlay: () => <div>No Group data</div>,
                                                GroupCellRender: GroupCellRender

                                            }}
                                            loadingOverlayComponent='customLoadingOverlay'
                                            noRowsOverlayComponent='customNoRowsOverlay'
                                            pagination
                                            paginationAutoPageSize
                                            enableCellTextSelection
                                            tooltipShowDelay={0}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>

            <Button onClick={() => { setDetails(true) }} className="ml-3" style={{ backgroundColor: themeColors.secondary, border: 'none', }}>
                <span style={{ fontSize: '0.8rem' }}>View</span>
            </Button>
        </>

    )
}

export default DetailsCellRender;

