import { Row, Col, Button, FormControl, FormGroup, Form } from 'react-bootstrap';
import { themeColors } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect, useRef } from "react"
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CSS from "./ActivateModal.module.css"
import { toast } from 'react-toastify';
import { Spinner } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import { OrderDetaulsCellRender } from "./OrderDetailsCellRender";
import { data } from 'jquery';

const PendingRequest = (props) => {
    const [show, setShow] = useState(false)
    const [value, setValue] = useState('');
    const [value2, setValue2] = useState('');
    const [orderID, setOrderId] = useState(null);
    const [threadId, setthreadId] = useState(null)
    const [orderRequests, setOrderRequests] = useState([])
    const [OrderRatePlan, setOrderRatePlan] = useState([])
    const [currentSelectedPlan, setCurrentedPlan] = useState(null)
    const [internalID, setInternalID] = useState(null)
    const [declineRequest, setDeclineRequest] = useState(false)
    const [completingRequest, setCompletingRequest] = useState(false)
    const [completingInProgress, setCompletingInProgress] = useState(false)
    const [currentLength, setCurrentLength] = useState(1)
    const [declineRequestInProgress, setDeclineInRequest] = useState(false)
    const [error, setEerror] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const textareaRef = useRef(null);
    const [currentLine, setCurrentLine] = useState(0);
    const [totalSIMQuantity, settotalSIMQuantity] = useState(0);

    let currentSimsData;
    //let totalSIMQuantity = 0;
    useEffect(() => {
        const id = props.location.pathname.split("/")[2]
        const internalId = props.location.pathname.split("/")[3]
        localStorage.setItem('props', JSON.stringify(this))
        setOrderId(id)
        setInternalID(internalId)
        if (orderID) {
            fetchOrderDetails()
        }

    }, [orderID])


    const fetchOrderDetails = () => {

        fetch("api/OrderController/OrderDetails", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),
                "OrderId": orderID

            })
        }).then(response => {
            return response.json().then(data => {
                TransformData(data)


            })

        })

    }


    const TransformData = (data) => {
        const mapFormat = new Map()
        data.forEach(sim => {
            let RatePlans = []
            if (mapFormat.get(sim['PlanName'])) {
                RatePlans.push(sim)
                const currentData = mapFormat.get(sim['PlanName'])
                const currentArray = [...currentData['PlanNames'], ...RatePlans]
                mapFormat.set(sim['PlanName'], { PlanNames: currentArray })

            }
            else {
                RatePlans = []
                RatePlans.push(sim)
                mapFormat.set(sim['PlanName'], { PlanNames: RatePlans })
            }
        })

        let newArray = []
        let newObject = {}
        for (let [key, value] of mapFormat.entries()) {
            newObject = {
                Name: key,
                Rateplans: value['PlanNames']
            }
            newArray.push(newObject)
        }
        settotalSIMQuantity(newArray.reduce((total, order) => total + order.Rateplans.length, 0));
        console.log("Quantity");
        console.log(totalSIMQuantity);
        setOrderRatePlan(newArray)

    }
    const RemoveSIMs = (data) => {
        setOrderRequests(orderRequests.filter(item => item.description != data))
    }

    const EnterSimValue = (data) => {
        const inputValue = data.target.value;
        const cursorPosition = data.target.selectionStart;
        const lines = inputValue.split('\n');

        for (let i = 0; i < lines.length; i++) {
            if (lines[i].length > 20) {
                const overflow = lines[i].length - 20;
                lines[i + 1] = (lines[i + 1] || '') + lines[i].substring(20);
                lines[i] = lines[i].substring(0, 20);
                if (cursorPosition > 20) {
                    data.target.selectionStart = cursorPosition + overflow;
                    data.target.selectionEnd = cursorPosition + overflow;
                }
            }
        }

        setValue(lines.join('\n'));
        setValue2(lines.join('\n'));
        setCurrentLine(getCurrentLine(inputValue, cursorPosition))
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            const cursorPosition = event.target.selectionStart;

            const lines = value.split('\n');
            const currentLineIndex = getCurrentLineIndex(cursorPosition);
            let position = cursorPosition;

            if (position === 0 && currentLineIndex > 0) {
                event.preventDefault();
                const lineToRemove = lines[currentLineIndex - 1];
                const newCursorPosition = lineToRemove.length;

                lines.splice(currentLineIndex - 1, 2, lineToRemove + lines[currentLineIndex]);
                setValue(lines.join('\n'));
                setValue2(lines.join('\n'));
                setCurrentLine(getCurrentLine(value, newCursorPosition));

                event.target.selectionStart = newCursorPosition;
                event.target.selectionEnd = newCursorPosition;
            }
        }
    };


    const getCurrentLineIndex = (cursorPosition) => {
        const lines = value.split('\n');
        let currentLineIndex = 0;
        let position = cursorPosition;

        for (let i = 0; i < lines.length; i++) {
            if (position <= lines[i].length) {
                currentLineIndex = i;
                break;
            }

            position -= lines[i].length + 1; // +1 to account for the newline character
        }

        return currentLineIndex;
    };

    const getCurrentLine = (inputValue, cursorPosition) => {
        const lines = inputValue.substring(0, cursorPosition).split('\n');
        return lines.length;
    };
    const SelectCurrentPlan = (name) => {
        setShow(true)
        const found = OrderRatePlan.filter(order => order['Name'] === name)
        setCurrentedPlan(found[0])


    }

    const CompleteRequest = () => {
        currentSimsData = value.split("\n").filter(sim => sim !== '')

        if (currentSelectedPlan == null) {
            toast.error(`Please add the correct quantity of SIMs to each Rate Plan before submitting.`)
            return;
        }
        if (totalSIMQuantity != orderRequests.length) {
            toast.error(`Please ensure the quantity of SIM Numbers entered matches the quantity assigned to this Rate Plan.`)
            return;
        }
        setCompletingRequest((prev) => { return !prev })
        setCompletingInProgress((prev) => { return !prev })
        const Payloaddata = {
            "threadId": uuidv4(),
            "createdDateTime": new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
            "orderType": "Activation",
            "provider": "Advantix",
            "payload": {
                "orderId": orderID, // Refer to Order Id from Order Summary endpoint
                "orderNumber": null,
                "internalOrderId": internalID, // Refer to Inernal Order Id from Order Summary endpoint
                "billingToken": null,
                "termsAndConditionsVerId": null,
                "primaryCarrier": null,
                "account": null,
                "charges": null,
                "ratePlanOrderItems": orderRequests, //change this 
                "paymentportal": null,
                "PortalUser": {
                    "PortalUserName": currentSelectedPlan['UserName'],
                    "FirstName": props['usageData']['CustomerName'].split(" ")[0],
                    "LastName": props['usageData']['CustomerName'].split(" ")[1],
                    "Email": props['usageData']['BillingContactEmail']
                }
            }
        }


        fetch("api/OrderController/ProvisionOrder", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),
                ...Payloaddata

            })
        }).then(response => {
            return response.json().then(data => {
                if (data['payload']['statusType'] === 'Fail') {
                    toast.error(data['payload']['errorDescription'])
                    setEerror(true)
                    setErrorMessage(data['payload']['errorDescription'])
                    setCompletingInProgress((prev) => { return !prev })
                    return;

                }
                else {
                    setCompletingInProgress((prev) => { return !prev })
                    setErrorMessage("")
                    setEerror(false)
                    toast.info("Provisioning Request submitted.")
                }


            })

        })
    }

    const DeclineRequest = () => {
        setDeclineInRequest((prev) => {
            return !prev;
        })
        fetch("api/OrderController/OrderDecline", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('sfAccountId'),
                "OrderId": orderID

            })
        }).then(response => {
            return response.json().then(data => {
                if (data['statusType'] === 'Success') {
                    setDeclineInRequest((prev) => {
                        return !prev
                    })
                    toast.info("Request declined.")
                    setDeclineRequest((prev) => { return !prev })
                    const history = props['history']
                    history.goBack()
                }


            }).catch(err => {
                console.log(err)
            })

        })


    }


    const FinishSIM = () => {
        currentSimsData = value.split("\n").filter(sim => sim !== '')
        if (currentSimsData.length == 0) {
            toast.error("Please enter the SIM Number(s) for the selected Rate Plan.")
            return;
        }
        for (const sims of currentSimsData) {
            if (sims.length < 20) {
                toast.error("Please enter a valid SIM Number. SIM Number must be 19 or 20 characters long.");
                return; // This will exit the validateSimsData function completely
            }
        }
        //currentSimsData.forEach(sims => {
        //    if (sims.length < 20) {
        //        toast.error("SIM Number must be 19 or 20 characters long")
        //        return;
        //    }
        //})
        //console.log("currentSimsData.length" + currentSimsData.length)
        if (orderRequests.filter(x => x.discription == currentSelectedPlan['Rateplans'][0]['PlanName']).length > currentSelectedPlan['Rateplans'].length) {
            toast.error("Please ensure the quantity of SIM Numbers entered matches the quantity assigned to this Rate Plan.")
            return;
        }
        let data = {}
        let tempResult = []

        for (let index = 0; index < currentSelectedPlan['Rateplans'].length; index++) {
            console.log("currentSimsData SIM: " + currentSimsData[index])
            if (orderRequests.filter(x => x.SIMNumber == currentSimsData[index]).length > 0 || tempResult.filter(x => x.SIMNumber == currentSimsData[index]).length > 0) {
                toast.error("Please remove duplicate SIM Numbers.")
                return;
            }

            if (orderRequests.filter(x => x.SIMNumber == currentSimsData[index]) == null || orderRequests.filter(x => x.SIMNumber == currentSimsData[index]).length == 0)
                data = {
                    "description": currentSelectedPlan['Rateplans'][index]['PlanName'], //Refer to PlanName from Order details
                    "SIMQuantity": "1", //missing
                    "unitCost": currentSelectedPlan['Rateplans'][index]['PlanCost'], // Refer Plan Cost from Order details
                    "SKU": currentSelectedPlan['Rateplans'][0]['SKU'], // SKU from Order details
                    "SIMNumber": currentSimsData[index],
                    "replacedSIMNumber": null,
                    "ItemStatus": currentSelectedPlan['Rateplans'][0]['ItemStatus']
                }
            tempResult.push(data)

        }

        if (tempResult.length !== currentSimsData.length) {
            toast.error("Please ensure the quantity of SIM Numbers entered matches the quantity assigned to this Rate Plan.")
            return;
        }
        if (orderRequests.length > 0) {
            setOrderRequests([...orderRequests, ...tempResult])
        }

        else {
            setOrderRequests(tempResult)
        }

        setShow(false)
        setValue2('');

    }


    return (
        <>

            <Modal show={completingRequest} onHide={() => { }} centered >
                <Modal.Body>
                    {completingInProgress ?
                        <Row style={{ padding: "1.2rem" }}>
                            <Col sm={12} style={{ textAlign: "center", marginBottom: "1rem" }}>
                                <span>Processing</span>
                            </Col>
                            <Spinner style={{ margin: "auto", display: "flex", fontSize: "1.1rem" }} />
                        </Row> :

                        error ? <div style={{ textAlign: "center" }}><p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Error Has Occured</p>
                            <p>{errorMessage}</p>
                            <div>  <Button onClick={() => {

                                setCompletingRequest((prev) => { return !prev; })

                            }}>Close</Button></div>
                        </div> : <Row style={{ padding: "1.2rem", textAlign: 'center' }}>
                            <Col sm={12} style={{ textAlign: "center", marginBottom: "1rem" }}>
                                <span>Process Complete</span>
                            </Col>
                            <Col>
                                <Button onClick={() => {
                                    setCompletingRequest((prev) => { return !prev; })
                                    const history = props['history']
                                    history.goBack()

                                }}>Close</Button>
                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>

            <Modal centered show={declineRequest} onHide={() => { }}>
                <Modal.Body>
                    {declineRequestInProgress ?
                        <Row style={{ padding: "1.2rem" }}>
                            <Col sm={12} style={{ textAlign: "center", marginBottom: "1rem" }}>
                                <span>Processing</span>
                            </Col>
                            <Spinner style={{ margin: "auto", display: "flex", fontSize: "1.1rem" }} />
                        </Row>
                        :
                        <Row style={{ padding: "1rem" }}>
                            <Col sm={12} style={{ textAlign: "center" }}>
                                <span>Do you want to decline this request?</span>
                            </Col>
                            <Col style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                <Button onClick={DeclineRequest} style={{ marginRight: "1rem" }} >Yes</Button>
                                <Button variant="secondary" onClick={() => setDeclineRequest((prev) => { return !prev })}>No</Button>
                            </Col>
                        </Row>}
                </Modal.Body>
            </Modal>





            <Modal size="lg" centered show={show} onHide={() => { }}>
                <Modal.Body style={{ paddingLeft: "2rem" }}>
                    <Row className="mt-3 " style={{ alignItems: "center" }} >
                        <Col sm={2} md={1} lg={1} xl={1} xs={3} className="field-root mr-1"    >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Quantity</span>
                        </Col>
                        <Col sm={2} md={1} lg={1} xl={1} xs={7} className=" manage-field-root" >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">{currentSelectedPlan?.['Rateplans'].length}</span>

                        </Col>
                        <Col sm={2} md={1} lg={1} xl={1} xs={3} className="field-root mr-2"    >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Rate Plan</span>
                        </Col>
                        <Col sm={2} md={1} lg={1} xl={2} xs={1} className=" manage-field-root" >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">{currentSelectedPlan?.['Name']}</span>

                        </Col>
                        <Col sm={2} md={2} lg={2} xl={12} className="field-root mr-1 mt-4 mb-1"    >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Enter SIM Number(s) below:</span>
                        </Col>
                        <Col lg={12}>
                            <FloatingLabel controlId="simsNumber" >
                                <Form.Control
                                    ref={textareaRef}
                                    value={orderRequests.filter(sim => sim.description == currentSelectedPlan?.['Name']).length > 0 ? orderRequests.filter(sim => sim.description == currentSelectedPlan?.['Name']).map((x => (x.SIMNumber + "\n").replace(/,/g, ''))) : value2}
                                    onKeyDown={handleKeyDown}
                                    onChange={(data) => {
                                        EnterSimValue(data)
                                    }}
                                    as="textarea"
                                    style={{ height: '300px', width: "100%" }}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col className="mt-2" lg={12} style={{ color: themeColors.secondary, fontSize: "0.8rem" }}>Formatted like:</Col>
                        <Col className="mt-2" lg={12} style={{ color: themeColors.secondary, fontSize: "0.8rem" }}>XXXXXXXXXXXXXXXXXXXXX</Col>
                        <Col className="mt-2" lg={12} style={{ color: themeColors.secondary, fontSize: "0.8rem" }}>XXXXXXXXXXXXXXXXXXXXX</Col>
                        <Col className="mt-2" lg={12} style={{ color: themeColors.secondary, fontSize: "0.8rem" }}>XXXXXXXXXXXXXXXXXXXXX</Col>
                        <Col className="mt-2" lg={12} style={{ color: themeColors.secondary, fontSize: "0.8rem" }}>XXXXXXXXXXXXXXXXXXXXX</Col>
                        <Col className="mt-4 mb-2" style={{ display: "flex", justifyContent: "space-between", padding: "1.5rem" }}>
                            <Button style={{ background: themeColors.primary }} variant="secondary" onClick={() => { FinishSIM() }}>
                                Finish
                            </Button>
                            <Button variant="secondary" onClick={() => { setShow(false) }}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {OrderRatePlan.length > 0 ?
                <>
                    <Row>
                        <Col xl={3} className="mb-4">
                            <h4 className='text-secondary mb-2'>Pending Request</h4>
                        </Col>
                        <Col xl={6} className="mb-4">
                            <Button onClick={() => setDeclineRequest((prev) => {
                                return !prev
                            })}><span>Decline Request</span></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2} xs={5} className="mb-4">
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Account Name</span>
                        </Col>
                        <Col xl={3} xs={7}>
                            <span className='text-secondary mb-2' style={{ whiteSpace: "nowrap", fontSize: "0.9rem" }}>{props?.['usageData']?.['CustomerName']}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2} xs={5} className="mb-4">
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Order Number</span>
                        </Col>
                        <Col xl={3} xs={7}>
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{internalID}</span>
                        </Col>
                    </Row>


                    <Row style={{ position: "relative" }}>
                        <Col xl={1} xs={7} sm={1} className=" mb-2">
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Quantity</span>
                        </Col>
                        <Col xl={4} xs={12} sm={3} className="mb-2" >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Rate Plan</span>
                        </Col>
                        <Col xl={2} xs={4} sm={1} className="mb-2" style={{ position: "relative" }} >
                            <span className={` ${CSS.hideSIM} live-data-title`} style={{ position: "relative", left: "-2rem", fontSize: "0.9rem" }}># of SIM(s) Assigned</span>
                        </Col>
                        {OrderRatePlan.map(order => {
                            return (
                                <Row>

                                    <Col xl={1} xs={7} sm={1} className=" mb-2" >
                                        <span style={{ fontSize: "0.9rem", marginLeft: "5px" }} className='text-secondary mb-2'>{order['Rateplans'].length}</span>
                                    </Col>
                                    <Col xl={4} xs={12} sm={3} className="mb-2" >
                                        <p style={{ fontSize: "0.9rem", marginLeft: "5px" }} className='text-secondary mb-2'>{order['Name']}</p>
                                    </Col>
                                    <Col xl={2} xs={4} sm={1} className="mb-2" style={{ position: "relative" }} >
                                        <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'> {orderRequests.filter(sim => sim.description == order['Name']).length}</span>
                                    </Col>
                                    <Col xl={2} xs={3} sm={2} className="mb-2">
                                        <Button data-id={order['Name']} onClick={(data) => {
                                            SelectCurrentPlan(data.target.dataset.id)

                                        }} style={{ background: "transparent", border: "none", padding: 0, margin: 0 }} className='text-secondary mb-2'>
                                            <span data-id={order['Name']} style={{ textDecoration: "underline", color: themeColors.primary, fontSize: "0.9rem" }}>Assign SIMS</span></Button>
                                    </Col>

                                    <Col xl={2} xs={3} sm={2} className="mb-2">
                                        <Button data-id={order['Name']} onClick={(data) => {
                                            RemoveSIMs(data.target.dataset.id)

                                        }} style={{ background: "transparent", border: "none", padding: 0, margin: 0 }} className='text-secondary mb-2'>
                                            <span data-id={order['Name']} style={{ textDecoration: "underline", color: themeColors.primary, fontSize: "0.9rem" }}>Remove SIMS</span></Button>
                                    </Col>

                                </Row>
                            )
                        })}
                        <Row className="mt-5 mb-5">
                            <h6 className='mb-3 fw-bold' style={{ fontSize: "0.9rem" }}>Order Created By</h6>
                            <Row >
                                <Col sm={12} md={4} lg={3} xl={2}  >
                                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Name</span>
                                </Col>
                                <Col sm={12} md={6} lg={3} xl={3} className="mb-3" >
                                    <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{localStorage.getItem('PortalUserFullname')} </span>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="" md={4} lg={3} xl={2}  >
                                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Email Address</span>
                                </Col>
                                <Col sm={12} md={6} lg={3} xl={3} className="mb-3" >
                                    <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{localStorage.getItem('PortalUsername')}</span>
                                </Col>
                            </Row>
                        </Row>


                        <Row style={{ alignItems: "" }}>
                            <Col xs={6} >
                                <Button
                                    onClick={() => {
                                        const history = props['history']
                                        history.goBack()
                                    }}

                                    style={{
                                        background: "transparent", border: "none", padding: 0, margin: 0, whiteSpace: "nowrap"
                                    }}><span
                                        style={{ color: themeColors.secondary }}
                                    >  <FontAwesomeIcon icon={faChevronLeft} fontSize='5x' style={{ fontSize: '1rem', marginRight: "0.4rem" }} />
                                        Return to Request</span></Button>
                            </Col>
                            <Col xs={6} >
                                <Button onClick={CompleteRequest} ><span style={{ whiteSpace: "nowrap" }}>Complete Request</span></Button>
                            </Col>
                        </Row>

                    </Row>
                </> : <span>NO data</span>

            }



        </>

    )

}


export default PendingRequest