
import React, { useRef, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import UsageCellRenderSim from "./UsageCellRenderSim"
import CustomTooltip from '../inventory/customTooltip';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import UsageSelector from "./UsageSelector"
import { Card, CardBody, Spinner } from "reactstrap";
import OverlayMenu from '../common/ContextMenu.js';

const UsageTable = ({ isOptionOpen, Opentoast, usageData, handleOption, detailData, mode, threshold, modeTitle, isrenderViewOpen,
    handleRenderView, handleAlertOpen, handleRenderViewSelection }) => {

    const usageGridRef = useRef(null)
    const [AccountName, setAccountName] = useState('')

    useEffect(() => {

        if (usageData) {
            setAccountName(usageData['CustomerName'])
        }

        if (mode === 'summary') {
            usageGridRef.current.api.refreshCells({ force: true })
        }


    }, [threshold, AccountName])


    function usernameFormatter(params) {
        return params.value?.split('|')[0];
    }


    const onBtExport = () => {
        usageGridRef.current.api.exportDataAsCsv({
            fileName: `${AccountName}_UsageReport_${mode}`, suppressQuotes: true, processCellCallback: function (cell) {
                if (typeof cell.value !== 'number') {
                    if (cell.value.indexOf(',') != -1) {
                        var stringValue = cell.value.replace(/,/g, "")
                        return stringValue;
                    }
                    else if (cell.column.colId === "PhoneNumber") {
                        return `'${cell.value}'`
                    }
                    else {
                        return cell.value
                    }
                }
                else {
                    return cell.value
                }
            }

        });
    };




    const columnDefs = [
        {
            headerName: 'Date',
            headerTooltip: "Date",
            field: 'Usagemonth',
            tooltipField: "Usagemonth",
            cellStyle: { marginLeft: '16px' },
            minWidth: 100,

        },

        {
            headerName: 'SIM Number',
            headerTooltip: "SIM Number",
            field: 'PhoneNumber',
            maxWidth: 220,
            minWidth: 200,
            cellRenderer: "UsageCellRenderSim",
            cellRendererParams: {
                mode: mode,
                threshold: threshold
            }

        },
        {
            headerName: 'Usage(MB)',
            headerTooltip: 'Usage',
            field: 'UsageQuantity',
            filter: 'agNumberColumnFilter',
            tooltipField: "UsageQuantity",
            cellStyle: { marginLeft: '16px' },
            valueGetter: UsageValueGetter,
            accentedSort: true,

            minWidth: 120,

            cellRendererParams: {
                mode: mode,
                threshold: threshold
            }
        },
        {
            headerName: 'Rate Plan',
            headerTooltip: 'Rate Plan',
            field: 'RatePlanName',
            tooltipField: "RatePlanName",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 300,
            minWidth: 270,
            filter: 'agTextColumnFilter',

        },

        {
            headerName: 'Service Type',
            headerTooltip: 'Service Type',
            field: 'ServiceType',
            tooltipField: "Serivice Type",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 250,
            minWidth: 130,
            filter: 'agTextColumnFilter',

        },
        {
            headerName: 'User Name',
            headerTooltip: "User",
            field: 'Username',
            valueFormatter: usernameFormatter,
            cellStyle: { marginLeft: '16px' },
            tooltipField: "Username",
            minWidth: 120,
            filter: 'agTextColumnFilter',

        },
        {
            headerName: '',
            headerTooltip: "Change Service",
            minWidth: 80,
            flex: 1,
            cellRenderer: "OverlayMenu",
            cellRendererParams: {
                mode: mode,
                threshold: threshold
            },
            cellStyle: { border: 'none' }

        }
    ];

    function UsageValueGetter(params) {
        let data = parseFloat(params.data.UsageQuantity)
        return data.toFixed(2);
    }
    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        sortingOrder: ['desc', 'asc', null],
        animateRows: true,


    };
    function onGridReady(params) {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };

    return (
        <>
            <Row className='d-flex'>
                <Col lg={12} style={{ justifyContent: "", alignItems: "flex-start" }} className="d-flex ">
                    <h4 className="text-secondary usage-header"  >Usage</h4>

                    <UsageSelector
                        Opentoast={Opentoast}
                        modeTitle={modeTitle}
                        isrenderViewOpen={isrenderViewOpen}
                        handleRenderView={handleRenderView}
                        handleAlertOpen={handleAlertOpen}
                        handleRenderViewSelection={handleRenderViewSelection} />
                    <div style={{ display: "flex", width: "100%", justifyContent: 'end' }}>
                        <svg onClick={handleOption} className="filter-icon-usage" style={{ width: '1.7rem', cursor: 'pointer', fill: "#5e6e82" }} type="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 
                            501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8
                            425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                        </svg>
                        <FontAwesomeIcon onClick={onBtExport} icon={faFileExcel} fontSize='5x' style={{ fontSize: '1.7rem', cursor: 'pointer', position: "relative", marginLeft: '1rem', top: '0.3rem' }} className='' />
                    </div>
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <div className="ag-theme-alpine grid">
                                <AgGridReact
                                    ref={usageGridRef}
                                    onRowDataChanged={(data) => {
                                        if (detailData) {
                                            setTimeout(() => {
                                                usageGridRef.current.api.hideOverlay()
                                            }, 10)
                                        }
                                        else {
                                            setTimeout(() => {
                                                usageGridRef.current.api.showLoadingOverlay()
                                            })
                                        }
                                        if (detailData && detailData.length <= 0) {
                                            setTimeout(() => {
                                                usageGridRef.current.api.showNoRowsOverlay()
                                            }, 10)


                                        }
                                    }}
                                    rowHeight={45}
                                    onGridReady={onGridReady}
                                    columnDefs={mode === 'summary' ? columnDefs.splice(1) : columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={detailData}
                                    frameworkComponents={{
                                        customLoadingOverlay: () => <Spinner type="grow" />,
                                        customNoRowsOverlay: () => <div>No Usage data</div>,
                                        customTooltip: CustomTooltip,
                                        UsageCellRenderSim: UsageCellRenderSim,
                                        OverlayMenu: OverlayMenu

                                    }}
                                    loadingOverlayComponent='customLoadingOverlay'
                                    noRowsOverlayComponent='customNoRowsOverlay'
                                    pagination
                                    paginationAutoPageSize
                                    enableCellTextSelection
                                    tooltipShowDelay={0}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )

}


export default UsageTable