import React, { useRef } from 'react';
import { Spinner } from "reactstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CustomTooltip from './customTooltip';
import "./InventoryTable.css";
import SimLink from "./SimLink"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody } from "reactstrap";
import OverlayMenu from '../common/ContextMenu.js';



function mrcFormatter(params) {
    if (typeof params.value === "number") {
        return `$${params.value}`;
    }
}

function formatNumber(number) {
    return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function usageFormatter(params) {
    return formatNumber(params.value) + ' MB';
}

function usernameFormatter(params) {
    return params.value?.split('|')[0];
}


const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    tooltipComponent: 'customTooltip',
    flex: 1,
};



const InventoryTable = ({ data, usageData }) => {
    const inventoryGridRef = useRef(null)
    console.log(usageData)



    const columnDefs = [
        {
            headerName: 'SIM Number',
            headerTooltip: "SIM Number",
            field: 'PhoneNumber',
            tooltipField: "PhoneNumber",
            minWidth: 200,
            cellRenderer: "SimLink"
        },
        {
            headerName: 'User',
            headerTooltip: "User",
            field: 'UserName',
            valueFormatter: usernameFormatter,
            cellStyle: { marginLeft: '16px' },
            tooltipField: "UserName",
            minWidth: 125
        },
        {
            headerName: 'Rate Plan',
            headerTooltip: 'Rate Plan',
            field: 'RatePlanName',
            tooltipField: "RatePlanName",
            cellStyle: { marginLeft: '16px' },
            minWidth: 250
        },
        {
            headerName: 'MRC',
            headerTooltip: 'MRC',
            field: 'MRC',
            valueFormatter: mrcFormatter,
            filter: 'agNumberColumnFilter',
            tooltipField: "MRC",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80
        },
        {
            headerName: 'Usage',
            headerTooltip: 'Usage',
            field: 'Usage',
            valueFormatter: usageFormatter,
            filter: 'agNumberColumnFilter',
            tooltipField: "Usage",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80
        },
        {
            headerName: '',
            headerTooltip: "Change Service",
            maxWidth: 80,
            minWidth: 70,
            flex: 1,
            cellRenderer: "OverlayMenu",
            cellStyle: { border: 'none' }
        }
    ];

    const onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };
    const onBtExport = () => {
        const { CustomerName }  =usageData
        inventoryGridRef.current.api.exportDataAsCsv({
            fileName: usageData = `${CustomerName}_Inventory_Report`, suppressQuotes: true, processCellCallback: function (cell) {
                if (typeof cell.value !== 'number') {
                    if (cell.value.indexOf(',') != -1) {
                        var stringValue = cell.value.replace(/,/g, "")
                        return stringValue;
                    }
                    else if (cell.column.colId === "PhoneNumber") {
                        return `'${cell.value}'`
                    }
                    else {
                        return cell.value
                    }
                }
               else {
                    return cell.value
                }
            }
        });
    };

    return (
        <>
       <Col lg={12} style={{ justifyContent: "space-between" }} className="d-flex mb-4 ">
                <h4 className="text-secondary">Inventory</h4>
                  <FontAwesomeIcon onClick={onBtExport} icon={faFileExcel} fontSize='5x' style={{ fontSize: '1.7rem', cursor: 'pointer', position: "relative", marginLeft: '1rem', top: '0.3rem' }} className='' />
               </Col>

            <Card>
                <CardBody>
        <div className="ag-theme-alpine grid">
                <AgGridReact
                    ref={inventoryGridRef}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={data}
                            frameworkComponents={{
                                customLoadingOverlay: () => <Spinner type="grow" />,
                                customNoRowsOverlay: () => <div>No inventory data</div>,
                                customTooltip: CustomTooltip,
                                SimLink: SimLink,
                                OverlayMenu: OverlayMenu
                            }}
                loadingOverlayComponent='customLoadingOverlay'
                noRowsOverlayComponent='customNoRowsOverlay'
                pagination
                paginationAutoPageSize
                enableCellTextSelection
                tooltipShowDelay={0}
            />
                    </div>
                </CardBody>
            </Card>
            </>
    );
};

export default InventoryTable;
